<template>
  <div>
    <q-dialog v-model="showFormModal" :persistent="false">
      <MastarFormModal
        v-if="showFormModal"
        :mode="formMode"
        :loading="loading"
        :form-model="formModel"
        :add="add"
        :update="update"
        @close="closeFormModal"
      >
        <template v-slot:form>
          <slot name="form" :form-mode="formMode"></slot>
        </template>
      </MastarFormModal>
    </q-dialog>
    <q-dialog v-model="showConfirmDeleteModal" :persistent="false">
      <MasterConfirmDeleteModal
        v-if="showConfirmDeleteModal"
        :form-model="formModel"
        :remove="remove"
        :remove-item-text-prop="removeItemTextProp"
        :remove-identifier="removeIdentifier"
        :loading="loading.delete"
        @close="onCloseDeleteModal"
      />
    </q-dialog>

    <q-table
      dense
      :title="title"
      :rows="rows"
      :columns="columnsWithAction"
      :row-key="rowKey"
      :loading="loading.getAll"
      :filter="filter"
      :rows-per-page-options="[0]"
      :class="$style.stickyHeader"
      style="height: calc(100vh - 68px - 50px)"
      flat
    >
      <template v-slot:top-left>
        <div class="flex items-center">
          <div class="text-h6">{{ title }}</div>
          <q-btn
            v-if="canCreate"
            class="q-ml-md"
            icon-right="mdi-plus-circle-outline"
            label="Lägg till"
            color="primary"
            @click="openFormModal('create')"
          />
        </div>
      </template>
      <template v-slot:top-right>
        <q-input debounce="300" v-model="filter" placeholder="Sök..." dense>
          <template v-slot:append>
            <q-icon name="mdi-magnify" />
          </template>
        </q-input>

        <q-btn
          v-if="exportData"
          class="q-ml-md"
          icon-right="mdi-file-export-outline"
          label="Export"
          color="grey-7"
          @click="onExport"
          :loading="exporting"
          outline
        />
      </template>

      <template v-slot:body-cell-color="props">
        <q-td :props="props">
          <q-badge :style="{ background: props.row.color }"></q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-roles="props">
        <q-td :props="props">
          <q-badge :style="{ background: 'rgba(0,0,0,.1)' }" class="text-black">
            {{ props.value }}

            <q-menu dark>
              <q-list dense dark separator style="max-height: 600px">
                <template v-for="role in props.row.roles" :key="role.id">
                  <q-item>
                    <q-item-section> {{ role.name }}</q-item-section>
                  </q-item>
                </template>
              </q-list>
            </q-menu>
          </q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-_action="props">
        <q-td :props="props">
          <q-btn-dropdown
            size="sm"
            flat
            round
            color="grey-7"
            dropdown-icon="mdi-dots-vertical"
            content-class="no-border-radius"
          >
            <q-list>
              <q-item
                clickable
                v-close-popup
                @click="actionItem.action(props.row)"
                v-for="actionItem in actionItems"
                :key="actionItem.label"
              >
                <q-item-section avatar>
                  <q-icon size="xs" :name="actionItem.icon" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ actionItem.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import MastarFormModal from './MasterFormModal.vue'
import MasterConfirmDeleteModal from './MasterConfirmDeleteModal.vue'
import { exportExcel } from '@/services/export-excel'
import { useRoute } from 'vue-router'
import { useProfile } from '@/composable/useProfile'

export type FormMode = 'create' | 'update' | 'copy'

export default defineComponent({
  name: 'MasterTable',

  components: {
    MastarFormModal,
    MasterConfirmDeleteModal,
  },

  emits: ['update-form-model', 'copy-form-model'],

  props: {
    exportData: {
      type: Array,
      required: false,
    },
    resetFormModel: {
      type: Function,
      required: true,
    },
    rowKey: {
      type: String,
      default: () => 'uuid',
    },
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    copy: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    loading: Object,
    add: {
      type: Function,
      default: () => {
        console.log('add')
      },
    },
    remove: {
      type: Function,
      default: () => {
        console.log('remove')
      },
    },
    removeIdentifier: {
      type: String,
      default: () => 'uuid',
    },
    update: Function,
    formModel: Object,
    removeItemTextProp: String,
  },

  setup(props, { emit }) {
    const filter = ref('')
    const showFormModal = ref(false)
    const formMode = ref<FormMode>('create')
    const showConfirmDeleteModal = ref(false)
    const exporting = ref(false)
    const { can } = useProfile()
    const route = useRoute()

    const canCreate = computed(() => can(`${route.meta.permissionPath}.create`))

    const actionItems = computed(() =>
      [
        {
          icon: 'mdi-pencil',
          label: 'Redigera',
          permission: `${route.meta.permissionPath}.update`,
          action: (row: Record<string, unknown>) => {
            emit('update-form-model', Object.assign({}, row))
            openFormModal('update')
          },
          visible: true,
        },
        {
          icon: 'mdi-content-copy',
          label: 'Kopiera',
          permission: `${route.meta.permissionPath}.create`,
          action: (row: Record<string, unknown>) => {
            emit('copy-form-model', Object.assign({}, row))
            openFormModal('copy')
          },
          visible: props.copy,
        },
        {
          icon: 'mdi-delete',
          label: 'Ta bort',
          permission: `${route.meta.permissionPath}.delete`,
          action: (row: Record<string, unknown>) => {
            emit('update-form-model', Object.assign({}, row))
            showConfirmDeleteModal.value = true
          },
          visible: true,
        },
      ].filter((actionItem) => can(actionItem.permission))
    )

    const columnsWithAction = computed(() => {
      return [
        ...props.columns,
        {
          name: '_action',
          label: '',
          align: 'right',
          field: '_action',
          sortable: false,
        },
      ]
    })

    function openFormModal(type: FormMode) {
      formMode.value = type
      showFormModal.value = true
    }

    function closeFormModal() {
      props.resetFormModel()
      showFormModal.value = false
    }

    async function onExport() {
      if (!props.exportData) return
      exporting.value = true
      const exportData = {
        name: props.title,
        sheets: [
          {
            name: props.title,
            data: props.exportData,
          },
        ],
      }
      exportExcel(exportData)

      exporting.value = false
    }

    function onCloseDeleteModal() {
      props.resetFormModel()
      showConfirmDeleteModal.value = false
    }

    return {
      showFormModal,
      showConfirmDeleteModal,
      columnsWithAction,
      filter,
      openFormModal,
      formMode,
      closeFormModal,
      actionItems,
      confirm,
      exporting,
      onExport,
      canCreate,
      onCloseDeleteModal,
    }
  },
})
</script>

<style lang="scss" module>
.stickyHeader {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
