
import { defineComponent, ref, computed } from 'vue'
import MastarFormModal from './MasterFormModal.vue'
import MasterConfirmDeleteModal from './MasterConfirmDeleteModal.vue'
import { exportExcel } from '@/services/export-excel'
import { useRoute } from 'vue-router'
import { useProfile } from '@/composable/useProfile'

export type FormMode = 'create' | 'update' | 'copy'

export default defineComponent({
  name: 'MasterTable',

  components: {
    MastarFormModal,
    MasterConfirmDeleteModal,
  },

  emits: ['update-form-model', 'copy-form-model'],

  props: {
    exportData: {
      type: Array,
      required: false,
    },
    resetFormModel: {
      type: Function,
      required: true,
    },
    rowKey: {
      type: String,
      default: () => 'uuid',
    },
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    copy: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    loading: Object,
    add: {
      type: Function,
      default: () => {
        console.log('add')
      },
    },
    remove: {
      type: Function,
      default: () => {
        console.log('remove')
      },
    },
    removeIdentifier: {
      type: String,
      default: () => 'uuid',
    },
    update: Function,
    formModel: Object,
    removeItemTextProp: String,
  },

  setup(props, { emit }) {
    const filter = ref('')
    const showFormModal = ref(false)
    const formMode = ref<FormMode>('create')
    const showConfirmDeleteModal = ref(false)
    const exporting = ref(false)
    const { can } = useProfile()
    const route = useRoute()

    const canCreate = computed(() => can(`${route.meta.permissionPath}.create`))

    const actionItems = computed(() =>
      [
        {
          icon: 'mdi-pencil',
          label: 'Redigera',
          permission: `${route.meta.permissionPath}.update`,
          action: (row: Record<string, unknown>) => {
            emit('update-form-model', Object.assign({}, row))
            openFormModal('update')
          },
          visible: true,
        },
        {
          icon: 'mdi-content-copy',
          label: 'Kopiera',
          permission: `${route.meta.permissionPath}.create`,
          action: (row: Record<string, unknown>) => {
            emit('copy-form-model', Object.assign({}, row))
            openFormModal('copy')
          },
          visible: props.copy,
        },
        {
          icon: 'mdi-delete',
          label: 'Ta bort',
          permission: `${route.meta.permissionPath}.delete`,
          action: (row: Record<string, unknown>) => {
            emit('update-form-model', Object.assign({}, row))
            showConfirmDeleteModal.value = true
          },
          visible: true,
        },
      ].filter((actionItem) => can(actionItem.permission))
    )

    const columnsWithAction = computed(() => {
      return [
        ...props.columns,
        {
          name: '_action',
          label: '',
          align: 'right',
          field: '_action',
          sortable: false,
        },
      ]
    })

    function openFormModal(type: FormMode) {
      formMode.value = type
      showFormModal.value = true
    }

    function closeFormModal() {
      props.resetFormModel()
      showFormModal.value = false
    }

    async function onExport() {
      if (!props.exportData) return
      exporting.value = true
      const exportData = {
        name: props.title,
        sheets: [
          {
            name: props.title,
            data: props.exportData,
          },
        ],
      }
      exportExcel(exportData)

      exporting.value = false
    }

    function onCloseDeleteModal() {
      props.resetFormModel()
      showConfirmDeleteModal.value = false
    }

    return {
      showFormModal,
      showConfirmDeleteModal,
      columnsWithAction,
      filter,
      openFormModal,
      formMode,
      closeFormModal,
      actionItems,
      confirm,
      exporting,
      onExport,
      canCreate,
      onCloseDeleteModal,
    }
  },
})
